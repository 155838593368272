<template>
  <v-container fluid>
    <TableViewComponent
      title="Table motifs congés"
      @addItemEvent="onAddReason()"
      addItemLabel="ajouter un motif de congés"
      itemLabel="motif de congés"
      itemsLabel="motifs de congés"
      :items="reasons"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce motif de congés?"
      :pagination="false"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { VacationReasonsService } from "@/service/dictionary/vacation_reasons_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableVacationReasons",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les éléments */
      reasons: [],

      /**le service d'accès à l'api */
      service: null,
    };
  },
  methods: {
    onAddReason() {
      this.$router.push(routes.vacationreasons.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.reasons = [];

        let reasons = await this.service.getAll();

        reasons.forEach((r) => {
          let reason = JSON.parse(JSON.stringify(r));

          //action de consultation
          reason.view = () => {
            this.$router.push(
              routes.vacationreasons.view.root + "/" + reason.id
            );
          };

          //action de modification
          reason.edit = () => {
            this.$router.push(
              routes.vacationreasons.edit.root + "/" + reason.id
            );
          };

          //action de suppression
          reason.delete = async () => {
            try {
              await this.service.delete(reason.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.reasons.push(reason);

          //   console.log(JSON.stringify(this.reasons, null, 4));
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Code",
          align: "start",
          sortable: true,
          value: "code",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditDictionary];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditDictionary, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadDictionary];
    },
  },
  mounted() {
    this.service = new VacationReasonsService(this.$api.getVacationReasonsApi());

    this.load();
  },
};
</script>

<style>
</style>